<template>
  <div class="layout-content">
    <el-alert
    v-if="showAlert === true"
    title="Y调查定于2024年5月7日开始迁移，期间所有Y调查均会下线。待迁移完毕后重新上线。感谢您的关注！"
    type="warning"
    center
    close-text="知道了"
    show-icon
    @close="closeTips">
  </el-alert>
    <!-- 头部 -->
    <div class="herder">
      <div class="logo" @click="skip('/')">
        <img src="@/assets/images/logo-multicolour.png" alt="图片" />
      </div>
      <div class="user-info" v-if="userInfo">
        <div class="money">
          <img src="@/assets/images/homePage/￥.png" alt="图片" />
          <span class="money-number">{{ userInfo.balance }} </span>
          <span>元</span>
          <el-button
            v-if="$route.path !== '/income/withdrawal'"
            @click="skip('/income/withdrawal?isShow=true')"
            >提现</el-button
          >
        </div>
        <div class="info-right">
          <div class="lang" @click="changeLang">{{ $t("lang") }}</div>
          <el-badge
            v-if="$store.state.application.noMessageNumber !== 0"
            :value="$store.state.application.noMessageNumber"
            :max="99"
            class="item"
          >
            <img
              class="envelope"
              @click="skip('/message/mynews')"
              src="@/assets/images/homePage/envelope.png"
            />
          </el-badge>
          <div v-else class="envelope-fa">
            <img
              class="envelope"
              @click="skip('/message/mynews')"
              src="@/assets/images/homePage/envelope.png"
            />
          </div>
          <img
            class="head"
            v-if="userInfo.avatarUrl"
            :src="userInfo.avatarUrl"
            alt="图片"
          />
          <img
            class="head"
            v-else
            src="@/assets/images/homePage/head.png"
            alt="图片"
          />
          <el-dropdown>
            <span class="el-dropdown-link" v-if="userInfo.name">
              {{ userInfo.name
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span class="el-dropdown-link" v-else>
              {{ userInfo.account | getAccount
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="exit">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div
            class="name"
            v-if="userInfo.gradeId === 3 || userInfo.gradeId === 4"
          >
            {{ userInfo.gradeId === 3 ? "银牌代理" : "金牌代理" }}
          </div>
          <div class="name" v-else-if="$store.state.application.isApplication">
            认证代理
          </div>
          <div class="name" v-else>未认证代理</div>
        </div>
      </div>
    </div>
    <div class="layout-main">
      <div class="layout-navigation">
        <div class="left">
          <img
            class="book"
            src="@/assets/images/homePage/rectangle.png"
            alt="图片"
          />
          <img
            src="@/assets/images/homePage/book.png"
            @click="skip('/resource')"
            alt="图片"
          />
        </div>
        <!-- 具体导航 -->
        <div :class="['navigation-main', { isCollapse: isCollapse }]">
          <div class="navigation-main-fa" ref="roll">
            <div
              v-for="(item, index) in navigation"
              :key="index"
              :class="[
                'navigation-arrow',
                { 'navigation-arrow-expand': item.arrow },
              ]"
            >
              <div
                :class="[
                  'navigation-line-fa',
                  { 'navigation-line-fa-active': firstIndex === index },
                ]"
              >
                <div
                  @click="
                    changeSelected(index, item.path, item.children.length)
                  "
                  :class="[
                    'navigation-line',
                    { 'navigation-line-active': firstIndex === index },
                  ]"
                >
                  <svg-icon class="icon" :icon-class="item.icon"></svg-icon>
                  <div class="name">{{ item.name }}</div>
                  <div class="arrow" @click.stop="changeArrow(index)">
                    <svg-icon
                      icon-class="arrow"
                      v-if="item.children.length !== 0"
                    ></svg-icon>
                  </div>
                </div>
                <div
                  class="navigation-line2"
                  @click="changeSelected2(index, item.path)"
                >
                  <svg-icon class="icon" :icon-class="item.icon"></svg-icon>
                </div>
              </div>

              <div class="children-line-fa">
                <div
                  v-if="item.children.length !== 0"
                  v-for="(cItem, cIndex) in item.children"
                  :key="cIndex"
                  @click.stop="changeChildren(index, cIndex, cItem.meta.path)"
                  :class="[
                    'children-line',
                    {
                      'children-line-active':
                        cIndex === secondIndex && firstIndex === index,
                    },
                  ]"
                >
                  {{ cItem.meta.title }}
                </div>
              </div>
            </div>
          </div>
          <!-- 浮动 -->
          <div
            :class="[
              'float-navigation',
              { 'float-navigation-active': thirdIndex === index },
            ]"
            v-for="(item, index) in navigation"
            :key="index"
            :style="'top:' + item.top + 'px;'"
          >
            <div v-if="item.children.length === 0">
              {{ item.name }}
            </div>
            <div
              v-else
              v-for="(cItem, cIndex) in item.children"
              :key="cIndex"
              @click="skip(cItem.path)"
            >
              {{ cItem.meta.title }}
            </div>
          </div>
          <!-- 控制导航的开关 -->
          <img
            :class="[
              'navigation-switch',
              { 'navigation-switch-close': isCollapse },
            ]"
            src="@/assets/images/homePage/switch.png"
            alt="图片"
            @click="isCollapse = !isCollapse"
          />
        </div>
      </div>
      <div :class="['router-main', { 'router-close': isCollapse }]">
        <div class="router-view">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>

<script>
import { afterLogin } from "@/router/afterLogin/index";
import { getApps, getMessagesUnreadCount } from "@/api/app";
import { getProfile } from "@/api/user";
import { removeStorage } from "@/utils/auth";
export default {
  filters: {
    //改变数据类型为字符串
    ChangeDataType(index, faIndex) {
      if (faIndex || faIndex === 0) index = faIndex + "-" + index;
      return String(index);
    },
    //用户名不存在，邮箱账号显示部分
    getAccount(value) {
      let start = value.substring(0, 3);
      let end = value.substring(value.length - 3, value.length);
      value = start + "***" + end;
      return value;
    },
  },
  data() {
    return {
      isCollapse: false, //导航开关
      navigation: [],
      navigation2: [], //备份
      firstIndex: 0,
      secondIndex: null,
      thirdIndex: null,
      showAlert: false,
    };
  },
  watch: {
    $route: "getPath",
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    //跳转
    skip(path) {
      this.$router.push(path);
    },
    // 改变选中
    changeSelected(index, path, type) {
      this.firstIndex = index;
      this.thirdIndex = null;
      this.secondIndex = 0;
      this.skip(path);
    },
    changeSelected2(index, path) {
      if (this.firstIndex === index) {
        if (!this.thirdIndex && this.thirdIndex !== 0) {
          this.thirdIndex = index;
        } else {
          this.thirdIndex = null;
        }
        return;
      }

      this.skip(path);
      this.firstIndex = index;
      this.secondIndex = null;
      this.thirdIndex = index;
    },
    changeChildren(index1, index2, path) {
      this.firstIndex = index1;
      this.secondIndex = index2;
      this.skip(path);
      console.log(1234, path);
    },
    changeArrow(index) {
      console.log(123);
      this.navigation[index].arrow = !this.navigation[index].arrow;
    },
    //滚动时重新赋值
    handleScroll() {
      this.navigation.forEach((_, index, array) => {
        array[index].top =
          this.navigation2[index].top - this.$refs.roll.scrollTop;
      });
    },
    //获取应用列表
    getApps() {
      const params = {
        page: 1,
        size: 16,
      };
      getApps(params).then((res) => {
        if (res.status === 200) {
          if (res.data.dataCount > 0) {
            this.$store.commit("application/setIsApplication", true);
          }
          this.$store.commit("application/setIsOK", true);
        }
      });
    },
    //获取个人基础信息
    getProfile() {
      getProfile().then((res) => {
        if (res.status === 200) {
          this.$store.commit("user/setUserInfo", res.data);
        }
      });
    },
    //退出
    exit() {
      this.$router.push("/login?loginType=3");
      this.$message({
        showClose: true,
        message: "退出成功,请重新登录",
        type: "success",
      });
      removeStorage("token");
    },
    //监听路由变化
    getPath() {
      console.log("路由变化");
      const routeList = JSON.parse(JSON.stringify(afterLogin));
      routeList.forEach((element, index) => {
        const obj = {
          children: element.isChildShow ? element.children : [],
          path: element.path,
        };

        if (this.$route.path.indexOf(obj.path) !== -1) {
          this.firstIndex = index;

          if (obj.children.length !== 0) {
            obj.children.forEach((cItem, cIndex) => {
              if (this.$route.path.indexOf(cItem.path) !== -1) {
                this.secondIndex = cIndex;
              }
            });
          }
        }
      });
    },
    //获取全部维度消息数量
    getMessagesUnreadCount() {
      getMessagesUnreadCount().then((res) => {
        if (res.status === 200) {
          this.$store.commit("application/setNoMessageNumber", res.data);
        }
      });
    },

    changeLang() {
      this.$i18n.locale = this.$i18n.locale === "en" ? "zh" : "en";
    },

    closeTips() {
      window.localStorage.setItem("userClicked", false);
    },
    showTips() {
      const visible = window.localStorage.getItem("userClicked") || true;
      this.showAlert = visible;
    }

  },
  created() {
    const routeList = JSON.parse(JSON.stringify(afterLogin));
    const arr = [];
    routeList.forEach((element, index) => {
      const obj = {
        name: element.name,
        isChildShow: element.isChildShow,
        children: element.isChildShow ? element.children : [],
        path: element.path,
        icon: element.icon,
        arrow: true,
        isChildren: false,
        top: 20 + index * 64,
      };
      arr.push(obj);

      if (this.$route.path.indexOf(obj.path) !== -1) {
        this.firstIndex = index;

        if (obj.children.length !== 0) {
          obj.children.forEach((cItem, cIndex) => {
            if (this.$route.path.indexOf(cItem.path) !== -1) {
              this.secondIndex = cIndex;
            }
          });
        }
      }
    });
    this.navigation = arr;
    this.navigation2 = JSON.parse(JSON.stringify(arr));
    //获取应用列表
    this.getApps();
    //获取个人基础信息
    this.getProfile();
    //未读消息
    this.getMessagesUnreadCount();

    this.showTips();
  },
  mounted() {
    // 监听滚动
    this.$nextTick(() => {
      if (this.$refs.roll) {
        this.$refs.roll.addEventListener("scroll", this.handleScroll);
      }
    });
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件
    if (this.$refs.roll) {
      this.$refs.roll.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>
<style lang="scss" scoped>
.layout-content {
  background-color: #f4f7fe;
  padding-top: 65px;
}
.herder {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  height: 65px;
  display: flex;
  .logo {
    width: 220px;
    cursor: pointer;
    img {
      width: 120px;
      height: auto;
      margin: 6px 50px;
    }
  }
  .user-info {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 220px);
    .money {
      display: flex;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
        margin-left: 20px;
        margin-right: 10px;
      }
      .money-number {
        font-weight: bold;
        margin-right: 5px;
      }
      .el-button {
        width: 60px;
        height: 28px;
        padding: 0;
        background: #4578ff;
        font-size: 14px;
        border-radius: 4px;
        color: #fff;
        margin-left: 20px;
      }
    }
    .info-right {
      display: flex;
      align-items: center;
      .el-dropdown {
        margin-right: 24px;
      }
      .name {
        margin-right: 20px;
        font-size: 14px;
      }
      .head {
        margin-right: 14px;
        width: 26px;
        height: 26px;
        border-radius: 26px;
      }
      .envelope {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      .el-badge {
        margin-right: 35px;
        margin-top: 8px;
      }
      .envelope-fa {
        margin-right: 35px;
        margin-top: 8px;
      }
      .lang {
        margin-right: 21px;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
      }
      .exit {
        width: 16px;
        height: 16px;
        margin-right: 21px;
        cursor: pointer;
      }
    }
  }
}
.layout-main {
  display: flex;
  min-height: calc(100vh - 65px);
}
.layout-navigation {
  display: flex;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 48px;
    border-right: 1px solid #e0e8fc;
    background-color: #fff;
    img {
      width: 16px;
      height: 16px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .book {
      width: 24px;
      height: 24px;
      margin-bottom: 20px;
    }
  }
}
.navigation-main-fa {
  height: calc(100vh - 65px);
  overflow-y: auto;
}
.navigation-main {
  width: 171px;
  position: relative;
  transition: width 0.3s;
  background-color: #fff;
  height: 100%;

  .navigation-switch {
    position: absolute;
    top: 35px;
    right: -6px;
    width: 11px;
    height: 11px;
    cursor: pointer;
  }
  .navigation-switch-close {
    transform: rotate(180deg);
  }
}
// 箭头旋转
.navigation-arrow {
  max-height: 65px;
  overflow: hidden;
  transition: max-height 0.3s;
  position: relative;
  .arrow {
    transform: rotate(180deg);
    transition: transform 0.3s;
    cursor: pointer;
  }
}

.navigation-arrow-expand {
  max-height: 200px;
  .arrow {
    transform: rotate(0);
    cursor: pointer;
  }
}
//浮动导航
.float-navigation {
  position: absolute;
  width: 90px;
  padding: 10px;
  padding-top: 5px;
  background-color: #fff;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.08);
  right: -120px;
  top: 20px;
  display: none;
  flex-direction: column;
  align-items: center;
  div {
    margin-top: 5px;
    cursor: pointer;
  }
  div:hover {
    color: #9091a5;
  }
}
.isCollapse {
  width: 65px;
  .navigation-line {
    display: none;
  }
  .navigation-line2 {
    display: flex;
  }
  .navigation-line-fa-active {
    color: #4578ff;
  }
  .children-line-fa {
    display: none;
  }
  .float-navigation-active {
    display: flex;
  }
}
.navigation-line-fa {
  height: 50.5px;
  padding-top: 14.5px;
}

.navigation-line {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  width: 145px;
  height: 36px;
  background-color: #fff;
  border-radius: 4px;
  margin-left: 10px;
  color: #06052d;
  cursor: pointer;
  .icon {
    width: 16px;
    height: 16px;
    margin: 0 18px 0 14px;
  }
  .arrow {
    position: absolute;
    right: 9px;
  }
}
.navigation-line-active {
  background-color: #4578ff;
  color: #fff;
}
.navigation-line2 {
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 16px;
  width: 100%;
  height: 36px;
  display: none;
  cursor: pointer;
  .icon {
    width: 16px;
    height: 16px;
    margin: 0 18px 0 14px;
  }
}

.children-line {
  width: 125px;
  padding-right: 20px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;

  color: #9091a5;
  margin-left: 10px;
  text-align: right;
}
.children-line-active {
  background-color: rgba(69, 120, 255, 0.1);
  color: #4578ff;
}

.router-main {
  width: calc(100% - 220px);
  background-color: #f4f7fe;
}
.router-close {
  width: calc(100% - 114px);
}
.router-view {
  width: 100%;
  height: calc(100vh - 65px);
  overflow: auto;
}
</style>
<style scoped>
.layout-main /deep/ .el-menu-vertical-demo {
  overflow: scroll;
}
.info-right >>> .el-badge__content {
  font-size: 0.5em;
  height: 12px;
  line-height: 12px;
  padding: 0 3px;
}
</style>
